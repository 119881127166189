<template lang='pug'>
#Dashboard.main-page.detail-page.pb-0
    loadingOverlay(v-if='loading' message='Loading . . .' :zIndex='999')
    #page-head.d-flex.align-center.justify-md-space-between(v-if='!loading')
        v-col.d-flex.align-center.px-0.pb-0.px-md-2.pb-md-4(cols='12' md='3')
            h1.my-0 {{ $t('DASHBOARD.TITLE') }}
        v-col(cols='12' md='9')
            v-row(no-gutters)
                v-col.pl-3
                    v-select(v-model='selectDateMode' :items='dateMode' item-value='index' item-text='name' prepend-inner-icon='mdi-calendar' height='36' flat dense solo @change='searchDeals')
                v-col.pl-3(v-if='selectDateMode === 5' cols='4')
                    v-row(no-gutters)
                        v-col.pr-1(cols='5')
                            v-menu(ref="menuStart" v-model="startDayMenu" :z-index='100' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto") 
                                template(v-slot:activator="{ on, attrs }")
                                    v-text-field(v-model='startDay' solo dense flat v-bind="attrs" v-on="on" readonly)
                                v-date-picker(v-model='startDay' @input='startDayMenu = false' no-title scrollable @change='searchDeals') 
                        v-col.pl-1.d-flex.align-center.justify-center(cols='2')
                            p.ma-0 － 
                        v-col.pl-1(cols='5')
                            v-menu(ref="menuEnd" v-model="endDayMenu" :z-index='100' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto") 
                                template(v-slot:activator="{ on, attrs }")
                                    v-text-field(v-model='endDay' solo dense flat v-bind="attrs" v-on="on" readonly)
                                v-date-picker(v-model='endDay' @input='endDayMenu = false' no-title scrollable @change='searchDeals' :min='startDay') 
                v-col.pl-3
                    v-select(v-model='process' :items='stageList' item-value='id' item-text='stage_type_name' prepend-inner-icon='icon-step' height='36' flat dense solo @change='searchDeals')
                v-col.pl-3.d-flex
                    v-select(v-model='people' :items='ownerList' item-value='id' item-text='fullName' prepend-inner-icon='icon-group' height='36' flat dense solo @change='searchDeals')
                    refreshBtn(@EmitRefresh='onEmitRefresh')
                //- v-col.pl-3(cols='1')
                    v-btn(text='text' color='primary' rounded @click='searchDeals()') 
                        v-icon(size='20') icon-search
    #page-inner(v-if='!loading')
        v-row.h-65
            v-col(cols='12' md='6' lg='5')
                v-card.px-2.py-5.h-100(flat)
                    v-row.align-center
                        v-col(cols='10')
                            h3.mb-0.d-flex.align-center
                                .cube-icon.small.bg-primary.mr-3
                                    v-icon(size='19' color='white') icon-chart
                                span {{$t('DASHBOARD.EXPECTED_REVENUE')}}
                        v-col.text-right(cols='2')
                            v-tooltip(bottom)
                                template(v-slot:activator='{ on, attrs }')
                                    v-btn.ml-auto(color='gary' outlined icon to='/Deals' v-bind='attrs' v-on='on')
                                        v-icon(size='20') icon-chatmoney-border
                                span {{$t('DASHBOARD.GO_OPPTY')}}
                        v-col(cols='6')
                            .text-caption {{$t('DASHBOARD.SUCCESSFULLY_CLOSED')}}
                            .text-h5.mt-1.ellipsis $ {{ toCurrecy(currentClose) }}
                        v-col(cols='6')
                            .text-caption {{$t('DASHBOARD.CURRENT_PERIOD')}}
                            .text-h5.mt-1.ellipsis $ {{ toCurrecy(endOfThePeriod) }}
                        v-col(cols='12')
                            bar-chart(v-if='showRevForecast' :class='showPipeline ? "mt-11" : "bar-chart-height"' :chart-data="chartData" :options='options')
            v-col(cols='12' md='6' lg='5')
                v-row.email-card-row(no-gutters :class='showPipeline ? "" : "h-100" ')
                    v-col(cols='12')
                        v-card.pa-4(flat :class='showPipeline ? "mb-5" : "h-100"')
                            h3.d-flex.align-center.mb-4
                                .cube-icon.small.bg-og.mr-3
                                    v-icon(size='19' color='white') icon-mail
                                span {{$t('DASHBOARD.EMAIL_PERFORMANCE')}}
                                v-badge.ml-auto(v-if='email[0].thisPeriod > 0' color='secondary' :content='email[0].thisPeriod' inline='inline')
                            .h-100.d-flex.align-start.justify-center
                                v-data-table.w-100(:headers="emailHeader" :items="email" hide-default-footer)
                                    template(v-slot:item.situation='{ item }')
                                        v-icon(v-if='item.situation == 0' size='14') mdi-send 
                                        v-icon(v-if='item.situation == 1' size='14') mdi-eye
                                        v-icon(v-if='item.situation == 2' size='14') mdi-link-variant
                                        p.ma-0.ml-2.d-inline-flex {{ getSituation(item.situation) }} 
                                    template(v-slot:item.minus='{ item }')
                                        p.ma-0.d-inline-flex {{ item.minus }}
                                        v-icon(v-if='item.minus < 0' size='14' color='red') icon-down-dir
                                        v-icon(v-else-if='item.minus > 0' size='14' color='success') icon-up-dir
                v-row.email-card-row.w-100(no-gutters)
                    v-card.pa-4.w-100(flat v-if='showPipeline')
                        h3.mb-2.d-flex.align-center
                            .cube-icon.small.bg-green.mr-3
                                v-icon(size='19' color='white') icon-chatmoney
                            span {{$t('DASHBOARD.STAGE_AMOUNT')}}
                        .d-flex.align-center.justify-center.px-2
                            funnel-bar-chart(v-if='showPipeline' :chart-data="funnelChartData" :options='funnelChartOptions' :height='250')
            v-col(cols='12' md='12' lg='2')
                v-card.most-won-card.h-100.pa-4.mb-4.w-100(flat)
                    h3.mb-2.d-flex.align-center
                        .cube-icon.small.bg-pink.mr-3
                            v-icon(size='19' color='white') mdi-crown
                        span {{$t('DASHBOARD.SALES_RANKING')}}
                    v-list
                        v-list-item.px-0(v-for='(item, index) in salesRankingList' :key='index')
                            v-list-item-avatar(color='aliceBlue' size='40')
                                v-img(v-if="item.usersetting.photo" :src='item.usersetting.photo')
                                v-icon(v-else) mdi-account
                            v-list-item-content
                                v-row(no-gutters)
                                    v-col(cols='8')
                                        v-chip(:href='onMail(item.email)').mb-1.ellipsis
                                            .t-black.ellipsis {{ item.last_name + ' ' + item.first_name }}
                                        span.t-black.d-block {{ item.oppty_count }} Deals
        v-row
            v-col(cols='12')
                v-card.pa-4.mb-5(flat)
                    .d-flex.align-center.justify-space-between
                        h3.mb-2.d-flex.align-center
                            .cube-icon.small.bg-purple.mr-3
                                v-icon(size='19' color='white') icon-chart
                            span.mr-3 {{$t('DASHBOARD.LATEST_OPPTY')}} 
                            span(style="font-size:15px;padding-top:12px") {{$t('DASHBOARD.WITHIN_30_DAYS')}}
                        v-tooltip(bottom)
                            template(v-slot:activator='{ on, attrs }')
                                v-btn.px-0(v-bind='attrs' v-on='on' min-width='40' text color='error')
                                    span {{ opptyPercentage }} %
                                    v-icon(size='20') icon-growth-chart
                            span(v-if='$i18n.locale == "zh_TW"') 全部: {{nowOpptyCount}} 商機 vs 前期: {{lastOpptyCount}} 商機
                            span(v-if='$i18n.locale == "zh_CN"') 全部: {{nowOpptyCount}} 商机 vs 前期: {{lastOpptyCount}} 商机
                            span(v-else) Total: {{nowOpptyCount}} Opportunities vs Last period: {{lastOpptyCount}} Opportunities
                    v-data-table.pb-9.dashboard-table.ellipsis(:headers='last30Headers' :items='opportunityList' :items-per-page='itemPerPage_30' :page.sync='page_30' :page-count='pageCount_30' multi-sort hide-default-footer :header-props="{sortIcon: 'mdi mdi-arrow-up'}"  :loading='latest_30_days_loading' loading-text="Loading..." , :sort-desc.sync='sortByDesc' :sort-by.sync="sortBy")
                        template(v-slot:item.owner.full_name='{ item }')
                            .ellipsis
                                a.pl-3(:href='onMail(item.owner.email)' :title='item.owner.last_name+ " " +item.owner.first_name') {{ item.owner.last_name+ " " +item.owner.first_name }} 
                        template(v-slot:item.accountName='{ item }')
                            .ellipsis
                                a(:href='item.accountId') {{ item.accountName }}
                        template(v-slot:item.name='{ item }')
                            .ellipsis
                                a(:href='"/Deal/"+item.id') {{ item.name }}
                        template(v-slot:item.current_stage ='{ item }')
                            .ellipsis
                                span {{ item.current_stage }}
                        template(v-slot:item.created_on='{ item }')
                            .ellipsis
                                span {{ item.created_on_adj }}
                        template(v-slot:item.amount='{ item }')
                            .ellipsis
                                span {{ item.amount_adj }}
                        template(v-slot:item.expected_close_date='{ item }')
                            .ellipsis
                                span {{ item.expected_close_date }}
                    v-pagination.py-1.mb-3( v-model='page_30' :length='pageCount_30' circle :total-visible="10" style='position: absolute; bottom: 0; width: 100%; height: 40px') 
                v-card.pa-4.mb-5(flat)
                    .d-flex.align-center.justify-space-between
                        h3.mb-2.d-flex.align-center
                            .cube-icon.small.bg-og.mr-3
                                v-icon(size='19' color='white') icon-chart
                            span.mr-3 {{$t('DASHBOARD.BEST_OPPTY')}}						
                            span(style="font-size:15px;padding-top:12px") {{$t('DASHBOARD.TOP_5')}}
                        span(style="color:#F05975" v-if='$i18n.locale == "zh_TW" || $i18n.locale == "zh_CN"') {{$t('SEGMENT_DETAIL.C_AMOUNT')}}: $ {{totalAmount}}
                        span(style="color:#F05975" v-else) Total Amount: $ {{totalAmount}}							
                    v-data-table.dashboard-table.ellipsis(:headers='top5OpenHeaders' :items='opportunityListTop5' :items-per-page='5' hide-default-footer multi-sort :header-props="{sortIcon: 'mdi mdi-arrow-up'}"  :loading='top5_loading' loading-text="Loading...")
                        template(v-slot:item.owner.full_name='{ item }')
                            .ellipsis
                                a.pl-3(:href='onMail(item.owner.email)' :title='item.owner.last_name+" "+item.owner.first_name') {{ item.owner.last_name+" "+item.owner.first_name }}
                        template(v-slot:item.accountName='{ item }')
                            .ellipsis
                                a(:href='item.accountId') {{ item.accountName }}
                        template(v-slot:item.name='{ item }')
                            .ellipsis
                                a(:href='"/Deal/"+item.id') {{ item.name }}
                        template(v-slot:item.current_stage='{ item }')
                            .ellipsis
                                span {{ item.current_stage }}
                        template(v-slot:item.created_on='{ item }')
                            .ellipsis
                                span {{ item.created_on_adj }}
                        template(v-slot:item.amount='{ item }')
                            .ellipsis
                                span {{ item.amount_adj }}
                        template(v-slot:item.expected_close_date='{ item }')
                            .ellipsis
                                span {{ item.expected_close_date }}
    Vframe
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog')
        error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
import barChart from '../assets/script/barChart';
import funnelBarChart from '../assets/script/horizontalBarChart';
import loadingOverlay from "@/components/Common/loadingOverlay";
import OpportunityDataService from "@/services/OpportunityDataService";
import StageDataService from "@/services/StageDataService";
import OrganizationDataService from "@/services/OrganizationDataService";
import dashboardDataService from "@/services/dashboardDataService";
import EmailTemplateDataService from "@/services/EmailTemplateDataService";
import errorDialog from '@/components/Dialog/errorDialog';
import i18n from '/common/plugins/vue-i18n.js' 
import refreshBtn from "@/components/Common/refreshBtn";
import Vframe from '@/components/UpChat/Vframe.vue';

export default {
    name: 'Dashboard',
    components: {
        barChart,
        funnelBarChart,
        loadingOverlay,
        errorDialog,
        refreshBtn,
        Vframe,
    },
    data() {
        return {
            sortByForSearch:[],
            sortByDesc:[],
            sortBy:[],
            loading: false,
            startDayMenu: false,
            startDay: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            endDayMenu: false,
            endDay: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            selectDateMode: 1,
            dateMode: [
                { index: 1, name: i18n.t('DASHBOARD.THIS_YEAR') },
                { index: 2, name: i18n.t('DASHBOARD.THIS_SEASON') },
                { index: 3, name: i18n.t('DASHBOARD.THIS_MONTH') },
                { index: 4, name: i18n.t('DASHBOARD.THIS_WEEK') },
                { index: 5, name: i18n.t('DASHBOARD.SELECT_DATE') },
            ],
            process: "all",
            people: "all",
            // Dialog
            errorDialog: false,
            errorMessage: '',
            // top30 & top5
            page_30: 1,
            pageCount_30: 0,
            itemPerPage_30: 10,
            latest_30_days_loading: false,
            top5_loading: false,
            date: '',
            panels: [0],
            last30Headers: [
                {
                    text: i18n.t('DASHBOARD.OPPTY_OWNER'),
                    align: 'left',
                    sortable: true,
                    value: 'owner.full_name',
                    width: '12%',
                },
                { text: i18n.t('DASHBOARD.CONTACT_NAME'), value: 'accountName', sortable: true, align: 'left', width: '15%'},
                { text: i18n.t('DASHBOARD.OPPTY_NAME'), value: 'name', sortable: true, align: 'left', width: '16%'},
                { text: i18n.t('DASHBOARD.CURRENT_STAGE'), value: 'current_stage', sortable: true, align: 'left', width: '16%'},
                { text: i18n.t('DASHBOARD.CREATED_TIME'), value: 'created_on', sortable: true, align: 'center', width: '16%'},
                { text: i18n.t('DASHBOARD.AMOUNT'), value: 'amount', sortable: true, align: 'right', width: '12%'},
                { text: i18n.t('DASHBOARD.EXPECTED_CLOSE_DATE'), value: 'expected_close_date', sortable: true, align: 'center', width: '10%'},
            ],
            top5OpenHeaders: [
                {
                    text: i18n.t('DASHBOARD.OPPTY_OWNER'),
                    align: 'left',
                    sortable: true,
                    value: 'owner.full_name',
                    width: '12%',
                },
                { text: i18n.t('DASHBOARD.CONTACT_NAME'), value: 'accountName', sortable: true, align: 'left', width: '15%'},
                { text: i18n.t('DASHBOARD.OPPTY_NAME'), value: 'name', sortable: true, align: 'left', width: '16%'},
                { text: i18n.t('DASHBOARD.CURRENT_STAGE'), value: 'current_stage', sortable: true, align: 'left', width: '16%'},
                { text: i18n.t('DASHBOARD.CREATED_TIME'), value: 'created_on', sortable: true, align: 'center', width: '16%'},
                { text: i18n.t('DASHBOARD.AMOUNT'), value: 'amount', sortable: true, align: 'right', width: '12%'},
                { text: i18n.t('DASHBOARD.EXPECTED_CLOSE_DATE'), value: 'expected_close_date', sortable: true, align: 'center', width: '10%'},
            ],
            // email 
            emailHeader: [
                {
                    align: 'start',
                    sortable: false,
                    value: 'situation',
                    width: '180px'
                },
                {
                    text: i18n.t('DASHBOARD.THIS_PERIOD'),
                    align: 'center',
                    sortable: false,
                    value: 'thisPeriod',
                },
                {
                    text: i18n.t('DASHBOARD.PREVIOUS'),
                    align: 'start',
                    sortable: false,
                    value: 'lastPeriod',
                },
                {
                    text: i18n.t('DASHBOARD.DIFFERENCE'),
                    align: 'center',
                    sortable: false,
                    value: 'minus',
                },
            ],
            email: [],
            opportunityList:[],
            opportunityListTop5:[],
            ownerList: [],
            stageList: [],
            opptyFilter: {
                "people":"all",
                "process":"all",
            },
            opptyPercentage:0,
            totalAmount:0,
            nowOpptyCount:0,
            lastOpptyCount:0,
            emilList:[],
            salesRankingList:[],
            searchData: {
                "mode":1,
                "startDate":null,
                "endDate":null
            },
            //Revenue Forecast
            revForecastLoading: true,
            currentClose: 0,
            endOfThePeriod: 0,
            showPipeline: false,
            showRevForecast: false,
            chartData: {
                labels: [],
                datasets: [
                    {
                        label: 'Won Deals',
                        data: [],
                        backgroundColor: 'rgb(0, 170, 96)',
                        stack: 'Stack 0',
                    },
                    {
                        label: 'Open Deals',
                        data: [],
                        backgroundColor: 'rgb(80, 140, 242)',
                        stack: 'Stack 0',
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value) {
                                if(value < 1000 ){
                                    return '$ ' + value;
                                }
                                else if(value >= 1000 && value <= 1000000){
                                    return '$ ' + value / 1000 + ' K';
                                }
                                else if(value >= 1000000 ){
                                    return '$ ' + value / 1000000 + ' M';
                                }
                            }
                        }
                    }]
                },
                tooltips: {
                    callbacks: {
                        label: function(value){
                            let amount = value.value.toString().split('.');
                            amount[0] = amount[0].replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                            return '$ ' + amount.join('.');
                        }
                    }
                }
            },
            funnelChartData: {
                labels: ['Lead In', 'Contact Made', 'Needs Defined', 'Proposal Made', 'NegoTiations Started'],
                datasets: [
                    {
                        data: [10,8,6,4,2],
                        backgroundColor: ['rgb(81,139,242)','rgb(242,160,65)','rgb(0, 170, 96)','rgb(123,104,238)','rgb(240,89,117)'],
                        categoryPercentage: 0.8,
                        barPercentage: 1.0,
                        stack: 'Stack 0',
                    },
                ]
            },
            funnelChartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        ticks: {
                            callback: function(value) {
                                if(value < 1000 ){
                                    return '$ ' + value;
                                }
                                else if(value >= 1000 && value <= 1000000){
                                    return '$ ' + value / 1000 + ' K';
                                }
                                else if(value >= 1000000 ){
                                    return '$ ' + value / 1000000 + ' M';
                                }
                            }
                        }
                    }]
                },
                tooltips: {
                    callbacks: {
                        label: function(value){
                            let amount = value.value.toString().split('.');
                            amount[0] = amount[0].replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                            return '$ ' + amount.join('.');
                        }
                    }
                },
                plugins: {
                    legend: false
                }
            },
        }
    },
    async created(){
        this.loading = true;
        await this.getOppty()
        await this.getEmailCount(this.searchData)
        // await this.getRevForecast();
        await this.getSalesRanking(this.searchData)
        
        OrganizationDataService.getchangeowner('opportunities')
        .then((respose)=>{
            this.ownerList = respose.data;			
            this.ownerList.forEach(item => {
                item.fullName = item.last_name + ' ' + item.first_name
            });
            this.ownerList.splice(0,0,{"fullName":i18n.t('GENERAL.ALL'),"id":"all"})
        });

        StageDataService.stageList("deal")
        .then(response => {
            this.stageList = response.data
            this.stageList.sort(function (a, b) {
                return a.id - b.id;
            });
            this.stageList.splice(0,0,{"stage_type_name":i18n.t('GENERAL.ALL'),"id":"all"})
        });
        this.loading = false;
    },
    methods: {
        onMail: function (mail) {
            return 'mailto:'+mail;
        },
        getFormatTime(data){
            let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',hour: '2-digit',minute: '2-digit',hour12: false };
            let today  = data;
            if(this.$i18n.locale == "zh_TW")
            {
                return today.toLocaleDateString("zh-TW", options); 
            }else
            {
                return today.toLocaleDateString("en-US", options);
            }                
        },
        async getOppty(){		
            this.latest_30_days_loading = true;
            this.top5_loading = true;
            this.showRevForecast = false;
            this.currentClose = 0;
            this.endOfThePeriod = 0;
            this.showPipeline = false;
            for(let i = 0; i < this.chartData.datasets.length; i++){
                this.chartData.datasets[i].data = [];
            }			
            
            await OpportunityDataService.dashboardList(this.opptyFilter, this.selectDateMode, this.startDay, this.endDay)
            .then(response => {
                this.opportunityList = response.data.top30;
                this.pageCount_30 = response.data.total_page;
                this.getChartData(response.data.statistic);
                this.getChartLabel(response.data.statistic);
                this.opportunityList.forEach(item=>
                {	
                    if(item.oppty_org_id === null){
                        item.accountId = "/Contacts/"+item.oppty_contact.id;
                    }
                    else{
                        item.accountId = "/Account/"+item.oppty_org_id;
                    }
                    item.amount_adj = "$ "+this.toCurrecy(item.amount);
                    item.created_on_adj =  this.getFormatTime(new Date(item.created_on));
                    item.owner.full_name = item.owner.last_name + item.owner.first_name;
                    if(item.oppty_org == null)
                    {					
                        item.accountName = item.oppty_contact.__chineseName__;
                    }
                    else{
                        item.accountName = item.oppty_org;
                    }
                })
                this.latest_30_days_loading = false;	
                this.opportunityListTop5 = response.data.top5;
                this.opportunityListTop5.forEach(item=>
                {
                    if(item.oppty_org_id === null){
                        item.accountId = "/Contacts/"+item.oppty_contact.id
                    }
                    else{
                        item.accountId = "/Account/"+item.oppty_org_id
                    }
                    item.amount_adj = "$ "+this.toCurrecy(item.amount)
                    item.created_on_adj =  this.getFormatTime(new Date(item.created_on));
                    item.owner.full_name = item.owner.last_name + item.owner.first_name
                    if(item.oppty_org == null)
                    {					
                    item.accountName = item.oppty_contact.__chineseName__        
                    }
                    else{
                    item.accountName = item.oppty_org       
                    }
                })
                
                this.top5_loading = false;	
                this.opptyPercentage = response.data.percentage
                this.totalAmount = 	this.toCurrecy(response.data.totalAmount)
                this.nowOpptyCount = response.data.total.now
                this.lastOpptyCount	= response.data.total.last
                if (this.opptyFilter.process != 'all') {
                    this.funnelChartData.labels = [];
                    this.funnelChartData.datasets[0].data = [];
                    this.funnelChartData.datasets[0].backgroundColor = [];
                    let dealFlow = response.data.dealFlow;
                    for(let i = 0; i < dealFlow.length; i++){
                        this.funnelChartData.labels.push(dealFlow[i].label);
                        this.funnelChartData.datasets[0].data.push(dealFlow[i].amount);
                        this.funnelChartData.datasets[0].backgroundColor.push(dealFlow[i].color);
                    }
                    this.showPipeline = true;
                }
            })
        },
        async getEmailCount(data){
            data.people = this.people;
            await EmailTemplateDataService.dashboardEmailList(data)
            .then(response => {
                this.email = response.data;
            })
        },
        async getSalesRanking(data){
            await dashboardDataService.getSalesRanking(data)
            .then(response => {
                this.salesRankingList = response.data;
            })
        },
        getSituation(num){
            switch(num){
                case 0:
                    return i18n.t('DASHBOARD.QUANTITY_SENT');
                case 1:
                    return i18n.t('DASHBOARD.READ');
                case 2:
                    return i18n.t('DASHBOARD.CLICKED');
                default:
                    return i18n.t('DASHBOARD.UNKNOWN');
            }
        },
        async searchDeals(){
            this.searchData = {
                "mode":this.selectDateMode,
                "startDate":this.startDay,
                "endDate":this.endDay
            }            
            await this.getEmailCount(this.searchData);
            await this.getSalesRanking(this.searchData)
            await this.getOppty();
        },
        toCurrecy(num){
            let parts = num.toString().split('.');
            parts[0] = parts[0].replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            return parts.join('.');
        },
        async getChartLabel(res){
            switch(this.selectDateMode){
                case 1:
                    this.chartData.labels = ['Jan', 'Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
                    break;
                case 2:
                    this.chartData.labels = this.getQuater();
                    break;
                case 3:{
                    let weeks = [];
                    for(let i = 0; i < res.length; i++){
                        weeks.push(`Week ${i+1}`);
                    }
                    this.chartData.labels = weeks;
                    break;}
                case 4:
                    this.chartData.labels = ['Sunday','Monday', 'Tuesday','Wednesday', 'Thursday','Friday','Saturday'];
                    break;
                case 5:
                    this.chartData.labels = this.getPeriod();
                    break; 
            }
        },
        getQuater(){
            let thisMonth = new Date().getMonth();
            if(thisMonth == 0 || thisMonth == 1 || thisMonth == 2){
                return ['Jan', 'Feb','Mar'];
            }
            else if(thisMonth == 3 || thisMonth == 4 || thisMonth == 5){
                return ['Apr','May','Jun'];
            }
            else if(thisMonth == 6 || thisMonth == 7 || thisMonth == 8){
                return ['Jul','Aug','Sep'];
            }
            else if(thisMonth == 9 || thisMonth == 10 || thisMonth == 11){
                return ['Oct','Nov','Dec'];
            }
        },
        getPeriod(){
            let startYear =  parseInt(this.startDay.substr(0,4));
            let endYear = parseInt(this.endDay.substr(0,4));
            let deviation = endYear - startYear;

            let start = this.startDay.substr(5,2);
            let end = this.endDay.substr(5,2);
            let arr = [];

            
            if(deviation == 0){
                for(let i = parseInt(start) ; i <= parseInt(end); i++){
                    arr.push(i);
                }
            }
            else if(deviation == 1){
                for(let i = parseInt(start); i <= 12; i++){
                    arr.push(i);
                }
                for(let i = 1; i <= parseInt(end); i++){
                    arr.push(i);
                }
            }	
            else if(deviation > 1){
                for(let i = parseInt(start); i <= 12; i++){
                    arr.push(i);
                }
                while(deviation-1 > 0){
                    for(let i = 1; i <= 12; i++){
                        arr.push(i);
                    }
                    deviation --;
                }
                for(let i = 1; i <= parseInt(end); i++){
                    arr.push(i);
                }
            }
            
            for(let i = 0; i< arr.length; i++){
                let name = this.findMonthName(arr[i]);
                arr[i] = name;
            }
            return arr;
        },
        findMonthName(m){
            let monthMap = new Map([
                [1, 'January'],
                [2,'Febuary'],
                [3,'March'],
                [4,'April'],
                [5,'May'],
                [6,'June'],
                [7, 'July'],
                [8,'August'],
                [9,'September'],
                [10,'October'],
                [11,'November'],
                [12,'December'],
            ]);

            return monthMap.get(m);
        },
        async getChartData(res){
            for(let i = 0; i < res.length; i++){
                this.chartData.datasets[0].data.push(res[i].won);
                this.chartData.datasets[1].data.push(res[i].open);

                this.currentClose += res[i].won * 100;
                this.endOfThePeriod += res[i].won * 100;
                this.endOfThePeriod += res[i].open * 100; 
                this.showRevForecast = true;
            }
            this.currentClose  = this.currentClose / 100;
            this.endOfThePeriod = this.endOfThePeriod / 100;
        },
        onEmitErrorDialog(){
            this.errorDialog = false;
        },
        async onEmitRefresh(){
            this.searchDeals();
        },
        async get_latest_30_days_data(){
            this.latest_30_days_loading = true;
            await OpportunityDataService.dashboard30DayList(this.opptyFilter, this.selectDateMode, this.startDay, this.endDay,this.page_30,this.sortByForSearch)
            .then(response => {
                this.opportunityList = response.data.list;
                this.pageCount_30 = response.data.total_page;
                this.opportunityList.forEach(item=>
                {	
                    if(item.oppty_org_id === null){
                        item.accountId = "/Contacts/"+item.oppty_contact.id;
                    }
                    else{
                        item.accountId = "/Account/"+item.oppty_org_id;
                    }
                    item.amount_adj = "$ "+this.toCurrecy(item.amount);
                    item.created_on_adj =  this.getFormatTime(new Date(item.created_on));
                    item.owner.full_name = item.owner.last_name + item.owner.first_name;
                    if(item.oppty_org == null)
                    {
                        item.accountName = item.oppty_contact.__chineseName__;
                    }
                    else{
                        item.accountName = item.oppty_org;
                    }
                })
                this.latest_30_days_loading = false;
            })
        },
    },	
    watch: {    
        "people": function (){
            this.opptyFilter.people = this.people;
        },
        "process": function (){
            this.opptyFilter.process = this.process;
        },
        "page_30": async function (){
            await this.get_latest_30_days_data();
        },
        "sortByDesc":async function () {
            this.sortByForSearch = JSON.parse(JSON.stringify(this.sortBy));
            for(let i in this.sortByDesc)
            {
                if(this.sortByDesc[i] == true)
                {
                    this.sortByForSearch[i] = '-'+this.sortByForSearch[i];
                }        
            }
            await this.get_latest_30_days_data();
        },
  },
}
</script>